import { call, put, select, takeEvery } from 'redux-saga/effects';

import { types } from '../actions.js';
import sessionSelectors from '../selectors';
import { initializeSession } from './initializeSession.js';
import { login } from './login.js';
import config from 'config/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { UnauthorizedErrorTypes } from 'features/shared/constants/session';
import { types as applicationActionTypes } from 'features/shared/services/application/actions.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import { succeedType } from 'features/shared/utils/actions.js';
import auth from 'features/shared/utils/auth';
import { getI18n } from 'features/sharedModules/customerConfig/services/selectors.js';

// TOOD: get user data on initialize

const AuthEventTypes = {
  loginSucceed: 'login_succeed'
};

function* onLoginSucceed(action) {
  const state = yield select();
  const currentCustomerId = sessionSelectors.getCurrentUserCustomerId(state);
  const accessToken = sessionSelectors.getAuth0AccessToken(state);

  yield put(
    notificationActionCreators.hideNotification(
      SubscriptionExpiredNotificationId
    )
  );
}

export function* onLogout(action) {
  const { unauthorizedErrorType } = action.payload;
  const i18n = yield select(getI18n);

  try {
    // Clear QAuth token from Redux first
    yield put({ type: types.SET_QAUTH_TOKEN, payload: { token: null } });

    // Handle unauthorized error if present
    if (
      unauthorizedErrorType === UnauthorizedErrorTypes.allModuleLicensesExpired
    ) {
      yield put(
        notificationActionCreators.showNotification({
          id: SubscriptionExpiredNotificationId,
          message: i18n('session.yourSubscriptionIsExpired'),
          type: NotificationTypes.error,
          timeout: 0
        })
      );
    }

    yield call(auth.logout);
  } catch (error) {
    console.error('Error during logout:', error);
  }
}

export function* onRelogin(action) {
  const { errorType, path } = action.payload.reloginData;

  if (!config.APPLICATION_DEBUG) {
    console.error('Relogin triggered by error: ', errorType);
  }

  try {
    // Clear QAuth token from Redux first
    yield put({ type: types.SET_QAUTH_TOKEN, payload: { token: null } });

    // Clear user from auth manager before reinitializing session
    yield call(auth.removeUser);

    // Trigger OIDC login
    yield call(auth.login, path);
  } catch (error) {
    console.error('Error during relogin:', error);
  }
}

export default function* () {
  yield takeEvery(applicationActionTypes.STARTED, initializeSession);

  yield takeEvery(types.LOGIN, login);

  yield takeEvery(succeedType(types.LOGIN), onLoginSucceed);

  yield takeEvery(types.LOGOUT, onLogout);

  yield takeEvery(types.RELOGIN, onRelogin);
}

const SubscriptionExpiredNotificationId = 'SubscriptionExpiredNotification';
