export const types = {
  INITIALIZE: 'SESSION/INITIALIZE',
  LOGIN: 'SESSION/LOGIN',
  RELOGIN: 'SESSION/RELOGIN',
  LOGOUT: 'SESSION/LOGOUT',
  SYNC_ADVISOR: 'SESSION/SYNC_ADVISOR',
  SET_QAUTH_TOKEN: 'SESSION/SET_QAUTH_TOKEN'
};

export const creators = {
  initialize: (auth0AccessToken, user, customerConfig) => ({
    type: types.INITIALIZE,
    payload: { auth0AccessToken, user, customerConfig }
  }),
  login: loginData => ({
    type: types.LOGIN,
    payload: {
      loginData
    }
  }),
  relogin: reloginData => ({
    type: types.RELOGIN,
    payload: {
      reloginData
    }
  }),
  logout: unauthorizedErrorType => ({
    type: types.LOGOUT,
    payload: { unauthorizedErrorType }
  }),
  setQAuthToken: token => ({
    type: types.SET_QAUTH_TOKEN,
    payload: { token }
  })
};
