import { put, call } from 'redux-saga/effects';

import { creators } from '../actions.js';
import {
  mapServerUserToClient,
  mapServerCustomerConfigToClient
} from '../mapping';
import { parseAccessToken, syncAdvisor } from './shared.js';
import { validateCustomerConfig } from './validateCustomerConfig';
import config from 'config/index.js';
import {
  getUserPageLanguage,
  setUserPageLanguage
} from 'features/pageLanguage/main/components/usePageLanguage';
import {
  getQAuthAccessToken,
  getUserInfo,
  getCustomerConfig
} from 'features/shared/api/index.js';
import auth from 'features/shared/utils/auth';

export function* initializeSession() {
  try {
    // Get user from auth manager
    const loggedInUser = yield call(auth.getUser);
    if (!loggedInUser || !loggedInUser.access_token) {
      yield put(creators.initialize(null, null, null));
      return;
    }

    const auth0AccessToken = loggedInUser.access_token;

    // Parse token and get user info
    const accessTokenPayload = parseAccessToken(auth0AccessToken);
    const userInfoData = yield call(
      getUserInfo,
      auth0AccessToken,
      accessTokenPayload[config.AUTH0_ID_KEY]
    );

    // Map user data
    const user = mapServerUserToClient(accessTokenPayload, userInfoData.data);
    const pageLanguage = getUserPageLanguage();

    // Get QAuth token
    const qAuthToken = yield call(
      getQAuthAccessToken,
      auth0AccessToken,
      undefined,
      true
    );
    if (!qAuthToken) {
      throw new Error('Failed to get QAuth token');
    }

    // Get customer config
    const customerConfig = yield call(
      getCustomerConfig,
      qAuthToken,
      user.customerCode,
      pageLanguage
    );

    const customerLanguage = customerConfig?.data?.customer?.language;
    const language =
      pageLanguage !== customerLanguage ? customerLanguage : pageLanguage;

    setUserPageLanguage(language);

    yield syncAdvisor({
      auth0AccessToken,
      user
    });

    const mappedCustomerConfig = mapServerCustomerConfigToClient(
      customerConfig.data
    );
    validateCustomerConfig(mappedCustomerConfig);

    // Initialize session with all data
    yield put(
      creators.initialize(auth0AccessToken, user, mappedCustomerConfig)
    );
  } catch (error) {
    console.error('Session initialization failed:', error);
    yield put(creators.initialize(null, null, null));
  }
}
