import {
  SigninRedirectArgs,
  UserManager,
  WebStorageStateStore
} from 'oidc-client-ts';
import queryString from 'query-string';

import config from 'config/index.js';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import routeTemplates from 'features/shared/utils/routeTemplates';

let metadata: Record<string, string> | undefined = undefined;

let extraQueryParams: Record<string, string> = {
  ui_locales: getUserPageLanguage() || ''
};
if (config.REACT_APP_AUTH_AUTH0_MODE) {
  const endSessionConfig = {
    returnTo: window.location.origin,
    client_id: config.REACT_APP_AUTH_PROVIDER_DEEP_ALPHA_CLIENT_ID
  };

  metadata = {
    authorization_endpoint: `${config.REACT_APP_AUTH_PROVIDER_DOMAIN}/authorize`,
    token_endpoint: `${config.REACT_APP_AUTH_PROVIDER_DOMAIN}/oauth/token`,
    issuer: `${config.REACT_APP_AUTH_PROVIDER_DOMAIN}/`,
    jwks_uri: `${config.REACT_APP_AUTH_PROVIDER_DOMAIN}/.well-known/jwks.json`,
    userinfo_endpoint: `${config.REACT_APP_AUTH_PROVIDER_DOMAIN}/userinfo`,
    end_session_endpoint: `${
      config.REACT_APP_AUTH_PROVIDER_DOMAIN
    }/v2/logout/?${queryString.stringify(endSessionConfig)}`
  };

  extraQueryParams.audience = config.AUTH_AUDIENCE;
}

if (config.REACT_APP_AUTH_PROVIDER_ADDITIONAL_QUERY_PARAMS !== '') {
  const additionalQueryParams = queryString.parse(
    config.REACT_APP_AUTH_PROVIDER_ADDITIONAL_QUERY_PARAMS
  );

  const keys = Object.keys(additionalQueryParams);

  keys.forEach(key => {
    extraQueryParams[key] = additionalQueryParams[key] as string;
  });
}

const settings = {
  authority: config.REACT_APP_AUTH_PROVIDER_DOMAIN,
  client_id: config.REACT_APP_AUTH_PROVIDER_DEEP_ALPHA_CLIENT_ID,
  redirect_uri: `${
    window.location.origin
  }${routeTemplates.loginRedirect.build()}`,
  response_type: config.REACT_APP_AUTH_PROVIDER_RESPONSE_TYPE,
  scope: config.REACT_APP_AUTH_PROVIDER_SCOPES,
  post_logout_redirect_uri: window.location.origin,
  extraQueryParams: extraQueryParams,
  metadata: metadata,
  monitorSession: false,
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  automaticSilentRenew: true,
  validateSubOnSilentRenew: true
};

const userManager = new UserManager(settings);

const addUserLoginListener = callback => {
  userManager.events.addUserLoaded(callback);
};

const addUserSilentRenewErrorListener = callback => {
  userManager.events.addSilentRenewError(callback);
};

const login = (redirectUrl?: string) => {
  const args: SigninRedirectArgs = {};
  if (redirectUrl) {
    args.redirect_uri =
      window.location.origin +
      routeTemplates.loginRedirect.build() +
      `?${queryString.stringify({
        redirectUrl
      })}`;
  }

  userManager.signinRedirect(args);
};

const loginCallback = () => userManager.signinRedirectCallback();

const getUser = async () => {
  return await userManager.getUser();
};

const getKeysEndpoint = async () => {
  return await userManager.metadataService.getKeysEndpoint();
};

const removeUser = async () => {
  return await userManager.removeUser();
};

const logout = async () => {
  try {
    const user = await getUser();
    if (!user) {
      // If no user, just redirect to home
      window.location.href = window.location.origin;
      return;
    }

    // Clear the user from storage first
    await userManager.removeUser();

    // Then trigger OIDC logout
    await userManager.signoutRedirect({
      id_token_hint: user.id_token,
      extraQueryParams: { ui_locales: getUserPageLanguage() || '' }
    });
  } catch (error) {
    console.error('Logout failed:', error);
    // Fallback to home page on error
    window.location.href = window.location.origin;
  }
};

const auth = {
  addUserLoginListener,
  addUserSilentRenewErrorListener,
  getKeysEndpoint,
  getUser,
  login,
  loginCallback,
  logout,
  removeUser
};

export default auth;
