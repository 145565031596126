import { call, put } from 'redux-saga/effects';

import { types } from '../actions.js';
import {
  mapServerCustomerConfigToClient,
  mapServerUserToClient
} from '../mapping';
import { parseAccessToken, syncAdvisor } from './shared.js';
import { useCrmLoginStore } from './useCrmLoginStore';
import { validateCustomerConfig } from './validateCustomerConfig';
import config from 'config/index.js';
import {
  getUserPageLanguage,
  setUserPageLanguage
} from 'features/pageLanguage/main/components/usePageLanguage';
import {
  getCustomerConfig,
  getQAuthAccessToken,
  getUserInfo
} from 'features/shared/api/index.js';
import {
  failedCreator,
  startedCreator,
  succeedCreator
} from 'features/shared/utils/actions.js';
import auth from 'features/shared/utils/auth';

export function* login(action) {
  yield put(startedCreator(types.LOGIN));

  useCrmLoginStore.getState().setEnableCrmLogin(false);

  try {
    const loggedInUser = yield call(auth.getUser);
    if (!loggedInUser || !loggedInUser.access_token) {
      return;
    }

    const auth0AccessToken = loggedInUser.access_token;

    // Parse token and get user info
    const accessTokenPayload = parseAccessToken(auth0AccessToken);
    const userInfoData = yield call(
      getUserInfo,
      auth0AccessToken,
      accessTokenPayload[config.AUTH0_ID_KEY]
    );

    // Map user data
    const user = mapServerUserToClient(accessTokenPayload, userInfoData.data);
    const pageLanguage = getUserPageLanguage();

    // Get QAuth token
    const qAuthToken = yield call(
      getQAuthAccessToken,
      auth0AccessToken,
      undefined,
      true
    );
    if (!qAuthToken) {
      throw new Error('Failed to get QAuth token');
    }

    // Get customer config
    const customerConfig = yield call(
      getCustomerConfig,
      qAuthToken,
      user.customerCode,
      pageLanguage
    );

    const customerLanguage = customerConfig?.data?.customer?.language;
    const language =
      pageLanguage !== customerLanguage ? customerLanguage : pageLanguage;
    setUserPageLanguage(language);

    yield syncAdvisor({
      auth0AccessToken,
      user
    });

    const mappedCustomerConfig = mapServerCustomerConfigToClient(
      customerConfig.data
    );

    validateCustomerConfig(mappedCustomerConfig);

    yield put(
      succeedCreator(types.LOGIN, {
        auth0AccessToken,
        user,
        customerConfig: mappedCustomerConfig
      })
    );
  } catch (e) {
    yield put(failedCreator(types.LOGIN, { errorCode: e.code }));
  }
}
