import * as R from 'ramda';
import { takeEvery, call, select } from 'redux-saga/effects';

import routerSelectors from 'features/shared/services/router/selectors.js';
import { types as sessionActionTypes } from 'features/shared/services/session/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { succeedType } from 'features/shared/utils/actions.js';
import { getI18n } from 'features/sharedModules/customerConfig/services/selectors';
import { types as routerActionTypes } from 'framework/router/services/actions.js';

function* identifyCurrentUser() {
  const state = yield select();
  const currentUser = sessionSelectors.getCurrentUser(state);

  if (R.isNil(currentUser)) {
    return;
  }
}

function* onInitialize() {
  yield call(identifyCurrentUser);
}

function* onLoginSucceed() {
  yield call(identifyCurrentUser);
}

function* onLocationInit() {
  const state = yield select();
  const route = routerSelectors.getRoute(state);
  const i18n = yield select(getI18n);

  const routeTitle = R.propOr('title.deepAlpha', 'title', route.route);
  document.title = i18n(routeTitle);
}

export default function* () {
  yield takeEvery(sessionActionTypes.INITIALIZE, onInitialize);

  yield takeEvery(succeedType(sessionActionTypes.LOGIN), onLoginSucceed);

  yield takeEvery(routerActionTypes.LOCATION_INIT, onLocationInit);
}
