import queryString from 'query-string';

import config from 'config/index.js';
import store from 'features/main/app/store.js';
import { creators as sessionActionCreators } from 'features/shared/services/session/actions.js';
import {
  customersApi,
  customersApiV2,
  deepAlphaApi,
  qAuthApi
} from 'features/shared/utils/axios';

export const getQAuthAccessToken = async (
  auth0AccessToken,
  cancelToken,
  force_update = false
) => {
  try {
    if (!force_update) {
      // First check if we have a valid token in Redux
      const state = store.getState();
      const existingToken = state.session_state?.qAuthToken;
      if (existingToken) {
        return existingToken;
      }
    }

    // If no token in Redux (or we want to force update), fetch a new one
    const auth = await qAuthApi.post(
      '/auth/token/authenticated',
      {
        application: 'deepalpha'
      },
      {
        cancelToken,
        headers: { Authorization: auth0AccessToken }
      }
    );

    store.dispatch(sessionActionCreators.setQAuthToken(auth.data.token));
    return auth.data.token;
  } catch (error) {
    console.error('Failed to get QAuth token:', error);
    store.dispatch(sessionActionCreators.setQAuthToken(null));
    return null;
  }
};

export const updateUserFavoriteItem = (
  currentCustomerId,
  accessToken,
  userFavoriteItemId,
  userFavoriteItem
) => {
  return deepAlphaApi.put(
    `/users/favorite-items/${userFavoriteItemId}`,
    {
      id: userFavoriteItem.id,
      favorite_item_id: userFavoriteItem.favoriteItemId,
      favorite_item_type: userFavoriteItem.favoriteItemType,
      user_id: userFavoriteItem.userId,
      is_favorite: userFavoriteItem.isFavorite
    },
    {
      headers: {
        'X-Customer-ID': currentCustomerId,
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

export const getUserInfo = (accessToken, currentUserId) => {
  return deepAlphaApi.get(`/users/${currentUserId}/info`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

export const getCustomerConfig = (
  accessToken,
  customerId,
  language,
  cancelToken
) => {
  const api = config.USE_LEGACY_CONFIGURATION_ENDPOINT
    ? customersApi
    : customersApiV2;

  return api.get(
    queryString.stringifyUrl(
      {
        url: `customers/${customerId}`,
        query: { language }
      },
      {
        skipNull: true
      }
    ),
    {
      cancelToken,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};
