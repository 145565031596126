import * as R from 'ramda';

import { types } from '../actions.js';
import { succeedType } from 'features/shared/utils/actions.js';
import { types as userProfileBasicPageActionTypes } from 'features/userProfile/services/actions';

const initialState = {
  auth0AccessToken: null,
  qAuthToken: null,
  currentUser: null,
  customerConfig: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INITIALIZE: {
      const { auth0AccessToken, user, customerConfig } = action.payload;

      return R.pipe(
        R.assoc('auth0AccessToken', auth0AccessToken),
        R.assoc('currentUser', user),
        R.assoc('customerConfig', customerConfig)
      )(state);
    }
    case succeedType(types.LOGIN): {
      const { auth0AccessToken, user, customerConfig } = action.payload;

      return R.pipe(
        R.assoc('auth0AccessToken', auth0AccessToken),
        R.assoc('currentUser', user),
        R.assoc('customerConfig', customerConfig)
      )(state);
    }
    case succeedType(userProfileBasicPageActionTypes.UPDATE_USER_PICTURE): {
      const { customPicture } = action.payload;

      return R.set(
        R.lensPath(['currentUser', 'customPicture']),
        customPicture,
        state
      );
    }
    case userProfileBasicPageActionTypes.DELETE_USER_PICTURE: {
      return R.set(R.lensPath(['currentUser', 'customPicture']), null, state);
    }
    case types.SET_QAUTH_TOKEN: {
      return R.assoc('qAuthToken', action.payload.token, state);
    }
    default:
      return state;
  }
};

export default reducer;
